.catalogue-header-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: white;
}

.catalogue-header-logos {
    display: flex;
    flex-direction: column;
    padding-right: 10vw;
    width: 23vw;
    align-items: flex-end;
}

.institution-logo-catalogue {
    margin-bottom: 22px;
    width: 220px;
}

.catalogue-header-title {
    width: 33vw;
}

.institution-name {
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    color: #333333;
    font-family: 'Fort-Light', sans-serif;
    font-weight: lighter;
    font-size: 24px;
    margin-left: 0;
    margin-bottom: 15px;
}

.catalogue-header-description {
    text-align: center;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
    font-family: 'Fort-Medium', sans-serif;
    color: #808080;
    font-size: 16px;
}

/* ---------------------- Responsive ---------------------- */
@media (max-width: 1060px) {
    .catalogue-header-logos {
        display: none;
    }
    .catalogue-header-title {
        width: 100%;
    }
}
