.catalogue-container {
    background-color: #f7f7f7;
}

.catalogue-list {
    padding: 30px;
    display: flex;
    justify-content: space-evenly;
    align-content: space-around;
    flex-wrap: wrap;
}

.exit-button-container {
    position: sticky;
    width: 100px;
    bottom: 5vh;
    left: 90vw;
}
