.logout-button {
    -moz-box-shadow:    0 3px 6px #00000029;
    -webkit-box-shadow: 0 3px 6px #00000029;
    box-shadow:         0 3px 6px #00000029;
    border-radius: 5px;
    background-color: #F4436C;
    color: white;
    font-family: 'Fort-Medium', sans-serif;
    border: none;
    padding: 8px 18px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
}

.logout-icon {
    margin-left: 2px;
}
