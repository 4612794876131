.access-button {
    -moz-box-shadow:    0 3px 6px #00000029;
    -webkit-box-shadow: 0 3px 6px #00000029;
    box-shadow:         0 3px 6px #00000029;
    border-radius: 5px;
    background-color: #10CFC9;
    color: #F0FBFF;
    font-family: 'Fort-Medium', sans-serif;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
}
