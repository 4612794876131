.auth-form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.auth-form-logos {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap-reverse;
}

.institution-logo {
    margin-bottom: 22px;
    width: 315px;
}

.input-group {
    width: 60%;
    border-radius: 25px;
    -moz-box-shadow:    2px 2px 5px 2px #ccc;
    -webkit-box-shadow: 2px 2px 5px 2px #ccc;
    box-shadow:         2px 2px 5px 2px #ccc;
    background-color: #F7F7F7;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    z-index: 2;
    position: relative;
}

.form-title {
    size: 16px;
    color: #808080;
    margin: 30px;
    font-family: 'Fort-Extrabold', sans-serif;
}

.button-group {
    margin: 10px 40px 40px;
}

.decor-image {
    height: 340px;
    width: 60%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;
    border-radius: 25px;
    margin-top: -35px;
    position: relative;
}

.redirect-button {
    padding: 15px;
    background-color: white;
    border: none;
    -moz-box-shadow:    2px 2px 5px 2px #ccc;
    -webkit-box-shadow: 2px 2px 5px 2px #ccc;
    box-shadow:         2px 2px 5px 2px #ccc;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 30px;
}

.auth-method-message {
    size: 16px;
    color: #808080;
    margin-top: 0;
    margin-bottom: 15px;
    font-family: 'Fort-Extrabold', sans-serif;
}

/* ---------------------- Responsive ---------------------- */
@media (max-width: 870px) {
    .auth-form-logos {
        justify-content: center;
    }
}
