.section-title {
    font-size: 18px;
    color: #333333;
    margin: 0;
    font-weight: bold;
}

.section-title-container {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.section-title-line {
    width: 0;
    height: 18px;
    border: solid 3px #2ebebd;
    margin-right: 3px;
}

.func-section-container {
    display: flex;
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 5%;
    flex-direction: column;
}

.section-functions-container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
}
