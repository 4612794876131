.tutorial-card {
    height: 400px;
    width: 40%;
    -moz-box-shadow:    0 3px 15px #00000029;
    -webkit-box-shadow: 0 3px 15px #00000029;
    box-shadow:         0 3px 15px #00000029;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 50px;
}

/* ---------------------- Responsive ---------------------- */
@media (max-width: 1060px) {
    .tutorial-card {
        margin-left: 10px;
        margin-right: 10px;
        width: 80%
    }
}
