.function-card-container {
    flex-basis: 296px;
    flex-shrink: 0;
    flex-grow: 0;
    height: 295px;
    background-color: white;
    margin-right: 40px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(120, 120, 120, 0.15);
}

.function-card-img {
    margin: 0;
    height: 50%;
}

.function-card-title {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 0;
}

.function-card-description {
    font-size: 13px;
    margin-left: 20px;
    margin-right: 20px;
    height: 45px;
}

.function-card-cta {
    background-color: #2ebebd;
    border-color: transparent;
    width: 171px;
    color: white;
    font-size: 14px;
    padding: 3px;
    border-radius: 6px;
    box-shadow: 0 3px 6px 0 rgba(0, 255, 220, 0.16);
}
