.microsoft-auth-button {
    padding: 15px;
    background-color: white;
    border: none;
    -moz-box-shadow:    2px 2px 5px 2px #ccc;
    -webkit-box-shadow: 2px 2px 5px 2px #ccc;
    box-shadow:         2px 2px 5px 2px #ccc;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 30px;
}

.gab-icon {
    margin-right: 10px;
}
