.create-user-form {
    width: 80%;
    margin: 10px 20px 20px 20px;
}

.information-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.error {
    background-color: rgb(244,67,108);
    padding: 5px;
    border-radius: 3px;
    color: white;
}

.notification {
    background-color: rgb(46, 189, 238);
    padding: 5px;
    border-radius: 3px;
    color: white;
}

.email-input-container {
    margin: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.email-input {
    background-color: white;
    border: none;
    -moz-box-shadow:    1px 1px 1px 1px #ccc;
    -webkit-box-shadow: 1px 1px 1px 1px #ccc;
    box-shadow:         1px 1px 1px 1px #ccc;
    border-radius: 3px;
    height: 20px;
    padding: 10px;
    width: 80%;
}

.password-input-container {
    margin: 15px;
    display: flex;
    justify-content: center;
}

.password-input {
    background-color: white;
    border: none;
    -moz-box-shadow:    1px 1px 1px 1px #ccc;
    -webkit-box-shadow: 1px 1px 1px 1px #ccc;
    box-shadow:         1px 1px 1px 1px #ccc;
    border-radius: 3px;
    height: 20px;
    padding: 10px;
    width: 80%;
}

.options-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.options {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.options input {
    background-color: #10CFC9;
    border: none;
    color: white;
    border-radius: 3px;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    font-weight: bold;
    -moz-box-shadow:    1px 1px 1px 1px #ccc;
    -webkit-box-shadow: 1px 1px 1px 1px #ccc;
    box-shadow:         1px 1px 1px 1px #ccc;
}

.options p {
    margin: 0;
    color: #10CFC9;
    cursor: pointer;
    font-weight: bold;
}

.options p:hover {
    text-decoration: underline;
}

.password-remember-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.password-remember {
    width: 80%;
    margin: 0;
    color: #10cfc9;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 20px;
}

.auth-method-message {
    size: 16px;
    color: #808080;
    margin-top: 0;
    margin-bottom: 15px;
    font-family: 'Fort-Extrabold', sans-serif;
}