@font-face {
  font-family: "Fort-Light";
  src: url("https://s3-sa-east-1.amazonaws.com/cdn.booklick.net/Fonts/Fort-Light.otf") format("opentype"),
}

@font-face {
  font-family: "Fort-Medium";
  src: url("https://s3-sa-east-1.amazonaws.com/cdn.booklick.net/Fonts/Fort-Medium.otf") format("opentype"),
}

@font-face {
  font-family: "Fort-Extrabold";
  src: url("https://s3-sa-east-1.amazonaws.com/cdn.booklick.net/Fonts/Fort-Extrabold.otf") format("opentype"),
}

body {
  margin: 0;
  font-family: 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
