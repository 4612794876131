.card-container {
    height: 280px;
    width: 200px;
    -moz-box-shadow:    0 3px 15px #00000029;
    -webkit-box-shadow: 0 3px 15px #00000029;
    box-shadow:         0 3px 15px #00000029;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 50px;
}

.catalogue-card-img {
    max-width: 150px;
    max-height: 80px;
    margin: 20px 0 0;
}

.catalogue-card-title {
    margin-top: 15px;
    margin-bottom: 0;
    color: #595959;
    font-family: 'Fort-Medium', sans-serif;
    font-size: 14px;
}

.catalogue-card-description {
    margin: 21px 8px 17px;
    color: #595959;
    font-family: 'Fort-Medium', sans-serif;
    font-size: 12px;
    height: 65px;
    overflow: hidden;
    text-align: center;
    line-height: 16px;
}

/* ---------------------- Responsive ---------------------- */
@media (max-width: 1060px) {
    .card-container {
        margin-left: 10px;
        margin-right: 10px;
    }
}
