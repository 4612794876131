.tutorial-container {
    background-color: #f7f7f7;
}

.cards-container {
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: baseline;
}
