.containerPopLocation{
    width: 500px;
    background: white;
    border-radius: 10px;
    display: flex;
    position: fixed;
    right: 0;
    left: 0;
    margin: auto; 
    box-shadow: 0 0 0 50vmax rgba(0,0,0,.5);
}

.mainPop{
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    
}
.listOption{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 35px;
}
.options{
    border-color: #2ebdee;
    border-radius: 5px;
    padding: 19px 18px;
    margin: 10px;
    color: #2ebdee;
    font-size: large;
    width: 190px;
    justify-content: center
}

/* .optionsSelect{
    background: #def6ff;
    color: #2ebdee;
    border:none;
} */

.options:focus{
    background: #def6ff;
    color: #2ebdee;
    border:none;
}

.continuar{
    background: #ef446d;
    border-width: 0;
    color: white;
    width: 100%;
    padding: 14px;
    border-radius: 7px;
    font-size: large;
}

.continuar:disabled{
    background: #aaa;
}