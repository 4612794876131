.proxy-logo-large {
    padding: 5px;
    height: 70px;
}

.proxy-logo-medium {
    padding: 5px;
    height: 50px;
}

.proxy-logo-small {
    padding: 5px;
    height: 30px;
    width: 200px;
}

.proxy-logo-cmp {
    padding: 5px;
    width: 100px;
    margin-right: 50px;
}
